/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import SuccessSnackbar from 'containers/common/components/SuccessSnackbar';

import { jwtDecode } from 'jwt-decode';
import moment from 'moment';
import axios from 'axios';

import { API_URL } from 'utilities/constants';
// IMAGES
import blankFile from 'assets/file_simple.svg';
import pdfFile from 'assets/file-pdf.svg';
import docFile from 'assets/file-doc.svg';
import cssFile from 'assets/file-css.svg';
import imgFile from 'assets/file-image.svg';
import sqlFile from 'assets/file-SQL.svg';
import excelFile from 'assets/excel.svg';
import aiFile from 'assets/file-AI.svg';

export const snackbarProviderProps = {
  preventDuplicate: true,
  anchorOrigin: { horizontal: 'center', vertical: 'bottom' },
  Components: { success: SuccessSnackbar },
};

/* THIS HELPER WILL RETURN PRICE AND CURRENCY (DEFAULT CURRENCY IS AED) */
export const getParsedPrice = (price = 0.0) => {
  if (price) {
    return Number(price?.toFixed(0));
  }

  return 'On Request';
};

/* THIS HELPER WILL TRANSFORM SEARCH PARAMS OBJECT INTO JAVASCRIPT PLAIN OBJECT */
export const getSearchParamsObj = searchParams => Object.fromEntries(searchParams);

export const getRandomImage = (images = []) => {
  const randomIndex = Math.floor(Math.random() * images.length);

  return images[randomIndex];
};

/* THIS HELPER WILL HELP US TRANSFORM THE SESSION STORAGE SEARCH PAYLOAD INTO SEARCH PARAMS */
export const getTransformedSearchParams = (
  search,
  isHotelList = false,
  isDirectSearch = false
) => {
  if (!search) {
    return {};
  }

  const paxRooms = search?.rooms?.map(room => ({
    Adults: room.adults,
    ChildrenAges: room.child_ages,
    Children: room.childrens,
  }));
  const params = {
    checkin: search?.start_date,
    checkout: search?.end_date,
    is_detailed_response: true,
    pax_rooms: paxRooms,
    guest_nationality: search?.guest_nationality,
    filters: {
      Refundable: false,
      NoOfRooms: 0,
      MealType: 'All',
    },
    ...(isDirectSearch && {
      direct_search: isDirectSearch,
      hotel_name: search?.hotel_name || undefined,
    }),

    ...(search?.hotel_code && {
      hotel_code: search?.hotel_code || undefined,
    }),

    ...(isHotelList && {
      city_name: capitalizeWords(search?.search) || undefined,
    }),
  };

  return params;
};
// NOTIFICATION HELPER FUNCTIONS
export const findUnreadNotificationCount = notifications => {
  if (notifications?.length > 0) return notifications[0]?.unread_notification_count;
  return 0;
};
// HELPER Function to format date
export const formatDate = (date, customFormat, initialFormat) => moment(date, initialFormat || 'YYYY-MM-DD HH:mm:ss').format(customFormat || 'DD MM YYYY');

// HELPER Function to get total adults and childrens
export function getTotalAdultsAndChildren(rooms) {
  let totalAdultsCount = 0;
  let totalChildrenCount = 0;

  rooms?.forEach(room => {
    totalAdultsCount += room.adults;
    totalChildrenCount += room.childrens;
  });

  return [totalAdultsCount, totalChildrenCount];
}

export const formatCancelationDate = dateString => moment(dateString, 'DD-MM-YYYY HH:mm:ss').format('D MMM, hh:mm A');

export const formatAfterCancelationDate = dateString => moment(dateString, 'DD-MM-YYYY HH:mm:ss')
  .add(1, 'minute')
  .format('MMM D, YYYY, hh:mm A');

export const getCardNumberLastDigits = cardNumber => {
  // Remove spaces from the string
  const stringWithoutSpaces = cardNumber.replace(/\s/g, '');

  // Get the last 4 characters
  const lastFourDigits = stringWithoutSpaces.slice(-4);

  return `•••• ${lastFourDigits}`;
};

export const createCountrySelectOptions = arr => arr?.map(item => ({ label: item, value: item }));

export const formatExpiryDate = dateString => moment(dateString).format('DD/YY');

export const getCreditCardAbbreviation = cardType => {
  const lowerCaseCardType = cardType?.toLowerCase();
  // Check if cardType contains certain keywords
  switch (true) {
    case lowerCaseCardType.includes('master'):
      return 'CA';
    case lowerCaseCardType.includes('visa'):
      return 'VI';
    case lowerCaseCardType.includes('american express'):
      return 'AX';
    case lowerCaseCardType.includes('diners'):
      return 'DC';
    case lowerCaseCardType.includes('discover'):
      return 'DS';
    case lowerCaseCardType.includes('japan credit bureau'):
      return 'JC';
    case lowerCaseCardType.includes('universal air travel plan'):
      return 'TP';
    case lowerCaseCardType.includes('unionpay'):
      return 'UP';
    default:
      return 'Unknown'; // or throw an error, depending on requirements
  }
};

// Generate select options data
export const transformSelectOptions = (array, label, value) => {
  if (array && array?.length > 0) {
    const optionsData = array?.map(item => ({
      label: item[label],
      value: item[value],
    }));
    return optionsData;
  }

  return [];
};
// Get label from options data using value
export const getLabelFromValueId = (array, value) => {
  if (!array || array?.length === 0) {
    return '';
  }
  const foundItem = array.find(item => item.value === value);
  return foundItem ? foundItem.label : '';
};

export const capitalizeWords = str => {
  let words = str?.split(' ');
  words = words?.map(word => word.charAt(0).toUpperCase() + word.slice(1));
  return words?.join(' ');
};

export const bookingIdStringJoin = arr => {
  if (typeof arr === 'string') {
    return arr; // return the single element as a string
  }
  return arr?.join(' , '); // join all elements with ' , '
};

export const getCancellationPolicyData = data => {
  const cancellationAmount = data?.room_cancellation?.[0]?.cancellation_amount || null;
  const noOfNights = data?.room_cancellation?.[0]?.number_of_nights || null;
  const percentage = data?.room_cancellation?.[0]?.percentage || null;

  return {
    cancellation_amount: cancellationAmount,
    no_of_nights: noOfNights,
    percentage,
  };
};

export const scrollToTop = () => window.scrollTo(0, 0);

export const formatName = (firstName, lastName, userName) => (!!firstName && !!lastName ? `${firstName} ${lastName[0]}` : userName);
export const getSorting = (order, orderBy) => {
  if (order === 'asc') {
    return (a, b) => {
      if (Number(a[orderBy]) < Number(b[orderBy])) {
        return -1;
      }
      if (Number(a[orderBy]) > Number(b[orderBy])) {
        return 1;
      }
      return 0;
    };
  }
  return (a, b) => {
    if (Number(a[orderBy]) > Number(b[orderBy])) {
      return -1;
    }
    if (Number(a[orderBy]) < Number(b[orderBy])) {
      return 1;
    }
    return 0;
  };
};

export const getDateSorting = (order, orderBy) => {
  if (order === 'asc') {
    return (a, b) => {
      if (a[orderBy] < b[orderBy]) {
        return -1;
      }
      if (a[orderBy] > b[orderBy]) {
        return 1;
      }
      return 0;
    };
  }
  return (a, b) => {
    if (a[orderBy] > b[orderBy]) {
      return -1;
    }
    if (a[orderBy] < b[orderBy]) {
      return 1;
    }
    return 0;
  };
};
export const setIconByFileType = type => {
  if (type.includes('pdf')) return pdfFile;
  if (type.includes('css')) return cssFile;
  if (
    type.includes('image') ||
    type.includes('png') ||
    type.includes('jpg') ||
    type.includes('jpeg') ||
    type.includes('svg')
  ) {
    return imgFile;
  }
  if (type.includes('ai')) return aiFile;
  if (type.includes('sql')) return sqlFile;
  if (
    type.includes('xls') ||
    type.includes('xlsx') ||
    type.includes('csv') ||
    type.includes('ms-excel') ||
    type.includes('spreadsheet')
  ) {
    return excelFile;
  }
  if (type.includes('doc')) return docFile;

  return blankFile;
};

export const formatFileSize = (sizeInBytes = 0) => {
  const decimal = 1;
  const marker = 1024;

  const toKB = (+sizeInBytes / marker).toFixed(decimal);
  const toMB = (+sizeInBytes / (marker * marker)).toFixed(decimal);
  const toGB = (+sizeInBytes / (marker * marker * marker)).toFixed(decimal);

  if (toKB < marker) return `${toKB} kb`;
  if (toMB < marker) return `${toMB} mb`;
  if (toGB < marker) return `${toGB} gb`;

  return +sizeInBytes;
};

export const toTitleCase = str => {
  if (!str || !str?.includes('_')) return str;
  return str
    ?.split('_') // Split the string by underscores
    .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
    .join(' ');
}; // Join the words with a space

export const isTokenExpired = token => {
  if (!token) return true;
  try {
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    return decodedToken.exp < currentTime;
  } catch (error) {
    return true;
  }
};

export const axiosAPiCall = async (endpoint, payload) => {
  const token = localStorage.getItem('token');
  const apiEndPoint = API_URL + endpoint;
  const response = await axios.post(apiEndPoint, payload, {
    headers: {
      Authorization: `JWT ${token}`,
    },
  });
  return response;
};

// Function to transform room_list to CustomerDetails
export const transformToCustomerDetails = roomList => roomList?.map(room => ({
  CustomerNames: room?.map(person => ({
    Title: person.title,
    FirstName: person.first_name,
    LastName: person.surname,
    Type: person.adult,
  })),
}));

// Helper function to strip HTML tags
export const removeHtmlTags = htmlString => {
  const doc = new DOMParser().parseFromString(htmlString || '', 'text/html');
  return doc.body.textContent || '';
};

export const transformAttractionsData = text => {
  // Extract content within <p> tags
  const matches = text.match(/<p>(.*?)<\/p>/gis);
  if (!matches) return [];

  // Combine all <p> content into a single string and split by <br /> tags
  const cleanedData = matches
    .map(pTag => pTag.replace(/<\/?p>/g, '')) // Remove <p> tags
    .join(' ')
    .split('<br />');

  // Process each line, skipping the first one
  return cleanedData
    .slice(1) // Skip the first line
    .map((line, index) => {
      const match = line.match(/^(.*?)-\s*(\d+(\.\d+)?\s*(km|mi))/i);
      if (!match) return null;

      const [_, title, distance] = match;
      return {
        id: index + 1, // Generate ID starting from 1
        title: title.trim(),
        distance: distance.trim(),
      };
    })
    .filter(Boolean); // Remove nulls (if any)
};

export const getCancellationPolicyColor = cancellationCharge => {
  if (cancellationCharge === 0) {
    return 'success';
  }
  if (cancellationCharge === 100) {
    return 'error';
  }
  return 'default';
};

export const getCancellationPolicyPdfColor = cancellationCharge => {
  if (cancellationCharge === 0) {
    return '#2e7d32';
  }
  if (cancellationCharge === 100) {
    return '#d32f2f';
  }
  return '#f5f5f5';
};

export const getCancellationPolicyText = (cancellationCharge, chargeType, fromDate) => {
  if (cancellationCharge === 0) {
    return `Free cancellation untill ${formatCancelationDate(fromDate)}`;
  }
  if (cancellationCharge === 100) {
    return 'No refund';
  }
  return `${cancellationCharge}${chargeType === 'Percentage' ? '$' : ''} refund untill ${formatCancelationDate(fromDate)}`;
};
