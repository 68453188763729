/* eslint-disable import/prefer-default-export */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_URL } from 'utilities/constants';
import { axiosAPiCall } from 'utilities/helpers';

const baseQuery = fetchBaseQuery({
  baseUrl: API_URL,
  prepareHeaders: headers => {
    const token = localStorage.getItem('token');
    if (token) {
      headers.set('Authorization', `JWT ${token}`);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  try {
    // Perform the initial query
    let result = await baseQuery(args, api, extraOptions);

    // If the query failed with a 401 error and it's not the refresh endpoint
    if (result.error?.status === 401 && args !== '/auth/me/') {
      const refreshToken = localStorage.getItem('refreshToken');

      if (refreshToken) {
        try {
          // Attempt to refresh the token
          const response = await axiosAPiCall('/refresh/', { refresh: refreshToken });

          if (response.data) {
            // Update tokens in local storage
            localStorage.setItem('token', response.data.access);
            localStorage.setItem('refreshToken', response.data.refresh);

            // Retry the original query with the new token
            result = await baseQuery(args, api, extraOptions);
          } else {
            throw new Error('Invalid response data from token refresh');
          }
        } catch (refreshError) {
          localStorage.removeItem('token');
          localStorage.removeItem('refreshToken');
          window.location.href = '/home';
          return result; // Ensure we exit after redirecting
        }
      } else {
        window.location.href = '/home';
        return result; // Ensure we exit after redirecting
      }
    }

    return result;
  } catch (error) {
    window.location.href = '/home';
    throw error; // Rethrow or handle the error as needed
  }
};

export const privateAPI = createApi({
  reducerPath: 'privateAPI',
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
});
