// LIVE DOMAIN
// export const API_URL = 'https://sublime.beyonderissolutions.com/api';

// STAGING DOMAIN
export const API_URL = process.env.REACT_APP_API_URL;
export const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;
export const GOOGLE_CLOUD_API = process.env.REACT_APP_GOOGLE_CLOUD_API_KEY;

// LOCAL DOMAIN
// export const API_URL = 'http://192.168.100.76:8000/api';

// BOOKING STATUS CONSTANTS
export const CONFIRMED = 'confirmed';
export const Cancelled = 'cancelled';
export const Confirmed = 'Confirmed';
export const APPROVED = 'CONFIRMED';
export const PENDING = 'PENDING';
export const CANCELLED = 'CANCELLED';
export const UNCONFIRMED = 'unconfirmed';
export const DECLINE = 'DECLINED';

// CUSTOMER SERVICE REPORT CONSTANTS
export const CLOSED_REPORT = 'closed';
export const PENDING_REPORT = 'pending';

// BOOKINGS TRIP TYPES
export const ONE_WAY = 'ONE_WAY';
export const ROUND_TRIP = 'ROUND_TRIP';
export const MULTI_LEG = 'MULTI_LEG';
export const RETURN = 'RETURN';
export const HALF_DAY = 'HALF_DAY';
export const FULL_DAY = 'FULL_DAY';
